import React, { useEffect } from 'react'
import { EventType } from '@azure/msal-browser/dist/event/EventType'
import { MsalProvider } from '@azure/msal-react'
import PropTypes from 'prop-types'
import publicClientApplication from './publicClientApplication'

function AuthProvider({ children }) {
  useEffect(() => {
    const accounts = publicClientApplication.getAllAccounts()
    if (accounts.length > 0) {
      publicClientApplication.setActiveAccount(accounts[0])
    }

    const callbackId = publicClientApplication.addEventCallback((event) => {
      const handleNewToken = (response) => {
        const { account } = response
        publicClientApplication.setActiveAccount(account)
      }

      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        handleNewToken(event.payload)
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        console.debug(event.eventType, event)
      }
    })

    return () => {
      publicClientApplication.removeEventCallback(callbackId)
    }
  }, [])

  return (
    <MsalProvider instance={publicClientApplication}>{children}</MsalProvider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default AuthProvider
