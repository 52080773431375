import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'
import {
  SideNavigation,
  TopNavigation,
  useNavigation,
} from '@sympaoy/pomegranate'
import { Outlet, useNavigate, useOutlet } from 'react-router-dom'
import SignIn from './pages/SignIn'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
  } 
  
  #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Layout = styled.div`
  display: flex;
  flex-grow: 1;
`

const Content = styled.div`
  flex-grow: 1;
  background-color: #f5f5f5;
  padding: 24px;
`

function App() {
  const initialState = {
    items: [
      {
        label: 'Organizations',
        icon: 'briefcase-blank-regular',
        href: '/organizations',
      },
      {
        label: 'Super Tools',
        icon: 'external-link-square-regular',
        href: `https://${document.location.host.replace(
          'backoffice.',
          ''
        )}/yllapito/tervetuloa.aspx`,
      },
    ],
    isClosed: false,
    isCollapsed: false,
  }

  const [navigation] = useNavigation(initialState)
  const navigate = useNavigate()
  const outlet = useOutlet()
  const { instance, accounts } = useMsal()

  useEffect(() => {
    if (!outlet && instance.getActiveAccount()) {
      navigate('/organizations')
    }
  }, [outlet, accounts])

  const handleOnSearchKeyDown = ({ code, target: { value: term } }) => {
    if (code === 'Enter') {
      navigate(`/search?term=${term.trim()}`)
    }
  }

  return (
    <>
      <GlobalStyle />
      <AuthenticatedTemplate>
        <TopNavigation
          onPress={() => {}}
          ariaLabel="teset"
          qaId="top-navigation"
          items={[
            {
              label: 'Log out',
              href: '/signout',
              id: 1,
              qaId: 'top-navigation__logout',
            },
          ]}
          onChange={() => {}}
          cancelText="Cancel"
          placeholder="Search"
          onSearchKeyDown={handleOnSearchKeyDown}
        />
        <Layout>
          <SideNavigation state={navigation} />
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
