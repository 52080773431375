import { Card, Switch, TextInput, PrimaryButton } from '@sympaoy/pomegranate'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'

const InputContainer = styled.div`
  width: 100%;
`

function IAMCard({ qaIdPrefix, configuration, onSave }) {
  return (
    <Formik
      initialValues={configuration || { enabled: false }}
      onSubmit={(values) => onSave(values)}
    >
      {(formikProps) => {
        const handleChange = (field, value) => {
          formikProps.setFieldValue(field, value)
          formikProps.handleChange(field)
        }

        return (
          <Form>
            <Card title="IAM" qaId={`${qaIdPrefix}__iam-card`}>
              <Switch
                qaId="organization__iam-card__azure-enabled"
                ariaLabel="Azure B2C Enabled"
                isSelected={formikProps.values.enabled}
                onChange={(value) => handleChange('enabled', value)}
                name="enabled"
                inputProps={{ 'aria-label': 'controlled' }}
              >
                Azure B2C Enabled
              </Switch>
              <InputContainer>
                <TextInput
                  qaId="organization__iam-card__technical-profile"
                  text="Technical Profile Name"
                  value={formikProps.values.technicalProfileReferenceId}
                  disabled={!formikProps.values.enabled}
                  onChange={(value) =>
                    handleChange('technicalProfileReferenceId', value)
                  }
                  name="technicalProfileReferenceId"
                />
              </InputContainer>
              <PrimaryButton
                qaId="organization__iam-card__save"
                onPress={formikProps.submitForm}
              >
                Save
              </PrimaryButton>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

IAMCard.propTypes = {
  qaIdPrefix: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    enabled: PropTypes.bool,
    technicalProfileReferenceId: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
}

IAMCard.defaultProps = {
  configuration: {
    enabled: false,
  },
}

export default IAMCard
