/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const GET_ORGANIZATION_BY_ID = gql`
  query GetOrganization($organizationId: Int!) {
    organization(id: $organizationId) {
      id
      name
      directory
      sso {
        enabled
        technicalProfileReferenceId
      }
    }
  }
`

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    organizations(
      first: $first
      after: $after
      last: $last
      before: $before
      order: { directory: ASC, name: DESC }
    ) {
      nodes {
        id
        name
        directory
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const SEARCH_ORGANIZATIONS = gql`
  query SearchOrganizations(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $searchTerm: String
  ) {
    organizations(
      first: $first
      after: $after
      last: $last
      before: $before
      order: { directory: ASC, name: DESC }
      where: {
        or: [
          { directory: { contains: $searchTerm } }
          { name: { contains: $searchTerm } }
        ]
      }
    ) {
      nodes {
        id
        name
        directory
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`
