/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const UPDATE_SSO_CONFIGURATION = gql`
  mutation ssoConfiguration(
    $organizationId: Int!
    $enabled: Boolean!
    $technicalProfileReferenceId: String!
  ) {
    configureSso(
      input: {
        organizationId: $organizationId
        enabled: $enabled
        technicalProfileReferenceId: $technicalProfileReferenceId
      }
    ) {
      organization {
        sso {
          enabled
          technicalProfileReferenceId
        }
      }
    }
  }
`
