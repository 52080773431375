import React, { useCallback, useMemo } from 'react'
import { Card } from '@sympaoy/pomegranate'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import IAMCard from '../components/organization/IAMCard'
import { GET_ORGANIZATION_BY_ID } from '../api/queries'
import { UPDATE_SSO_CONFIGURATION } from '../api/mutations'

const Container = styled.div`
  display: flex;
  gap: 24px;
`

function Organization() {
  const { id } = useParams()
  const organizationId = useMemo(() => parseInt(id, 10), [id])

  const { data, loading } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: {
      organizationId,
    },
  })

  const [saveB2CConfiguration] = useMutation(UPDATE_SSO_CONFIGURATION)

  const saveAzureB2CConfiguration = useCallback(async (callbackData) => {
    const response = await saveB2CConfiguration({
      variables: { organizationId, ...callbackData },
    })

    if (response.errors) {
      throw response.errors
    }
  })

  return (
    !loading &&
    data && (
      <Container>
        <Card title="Info" qaId="organization__info-card">
          ID: {data.organization.id}
          <br />
          Code: {data.organization.directory}
          <br />
          Name: {data.organization.name}
        </Card>
        <IAMCard
          qaIdPrefix="organization"
          organizationId={organizationId}
          configuration={data.organization.sso}
          onSave={saveAzureB2CConfiguration}
        />
      </Container>
    )
  )
}

export default Organization
