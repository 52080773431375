import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Heading } from '@sympaoy/pomegranate'
import { SEARCH_ORGANIZATIONS } from '../api/queries'
import OrganizationsTable from '../components/organization/OrganizationsTable'

function Search() {
  const [searchParams] = useSearchParams()
  const term = searchParams.get('term')

  const DEFAULT_QUERY_VARIABLES = {
    first: OrganizationsTable.defaultProps.rowsPerPage,
    searchTerm: term,
  }

  const [organizationsTablePage, setOrganizationsTablePage] = React.useState(
    OrganizationsTable.defaultProps.page
  )
  const [organizationsTableRowsPerPage, setOrganizationsTableRowsPerPage] =
    React.useState(OrganizationsTable.defaultProps.rowsPerPage)
  const [queryVariables, setQueryVariables] = React.useState(
    DEFAULT_QUERY_VARIABLES
  )
  const { data, loading } = useQuery(SEARCH_ORGANIZATIONS, {
    variables: queryVariables,
  })

  const handleOnOrganizationsTablePageChange = (event, newPage) => {
    setOrganizationsTablePage(newPage)
    setQueryVariables(
      newPage > organizationsTablePage
        ? {
            first: organizationsTableRowsPerPage,
            after: data.organizations.pageInfo.endCursor,
            searchTerm: term,
          }
        : {
            last: organizationsTableRowsPerPage,
            before: data.organizations.pageInfo.startCursor,
            searchTerm: term,
          }
    )
  }

  const handleOnOrganizationTableRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setOrganizationsTableRowsPerPage(newRowsPerPage)
    setOrganizationsTablePage(OrganizationsTable.defaultProps.page)
    setQueryVariables({ first: newRowsPerPage })
  }

  useEffect(() => {
    setQueryVariables({
      first: organizationsTableRowsPerPage,
      searchTerm: term,
    })
  }, [term])

  return (
    !loading && (
      <div>
        <Heading
          content={`Search results for: ${term}`}
          tag="h1"
          fontWeight="Bold"
        />
        <Heading content="Organizations" tag="h2" />
        {data && data.organizations.totalCount > 0 ? (
          <OrganizationsTable
            organizations={data.organizations.nodes}
            totalCount={data.organizations.totalCount}
            page={organizationsTablePage}
            rowsPerPage={organizationsTableRowsPerPage}
            onPageChange={handleOnOrganizationsTablePageChange}
            onRowsPerPageChange={handleOnOrganizationTableRowsPerPageChange}
          />
        ) : (
          <Heading color="neutral" content="No organizations found" tag="h5" />
        )}
      </div>
    )
  )
}

export default Search
