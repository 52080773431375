import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import PropTypes from 'prop-types'
import useAccessToken from '../auth/useAccessToken'

function ApolloConnection({ children }) {
  const { getAccessToken } = useAccessToken()

  const withToken = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  })

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
  })

  const client = new ApolloClient({
    link: from([withToken, httpLink]),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

ApolloConnection.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ApolloConnection
