import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function SignOut() {
  const { instance } = useMsal()
  const navigate = useNavigate()
  const [activeAccount, setActiveAccount] = useState({})

  useEffect(() => {
    setActiveAccount(instance.getActiveAccount())
  }, [])

  useEffect(() => {
    if (activeAccount) {
      instance.logout()
    } else {
      navigate('/')
    }
  }, [activeAccount])

  return <div>Logging out...</div>
}

export default SignOut
