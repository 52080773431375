import React from 'react'
import App from './App'
import Organizations from './pages/Organizations'
import Organization from './pages/Organization'
import SignOut from './pages/SignOut'
import Search from './pages/Search'

export default [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'organizations',
        element: <Organizations />,
      },
      {
        path: 'organizations/:id',
        element: <Organization />,
      },
      {
        path: 'signout',
        element: <SignOut />,
      },
      {
        path: 'search',
        element: <Search />,
      },
    ],
  },
]
